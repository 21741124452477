<template>
  <div id="app">
    <keep-alive exclude="newsDetail,exchangeDetail,productDooryardDetail">
      <router-view  v-if="isRouterAlive"/>
    </keep-alive>
  </div>
</template>

<script>
import utils from '@/common/utils'
import 'animate.css';
export default {
  name: 'app',
  provide() { // 注册一个方法
      return {
        reload: this.reload
      }
    },
    data() {
      return {
        isRouterAlive: true
      }
    },
    methods: {
      reload() {
        this.isRouterAlive = false
        this.$nextTick(function() {
          this.isRouterAlive = true;
        })
      }
    },
  created () {
    if (utils._isMobile()) {
      window.location.href = 'http://m.xiaoyuan.com.cn' + window.location.pathname + window.location.search
    }
  }
}
</script>
<style>
@import './common/css/base.css';
@import './common/css/iconfont.css';
@import './common/css/animate.min.css';
/* 改样式用于改写el-cascader高度 */
	.el-cascader-menu {
	  height: 400px;
	}
</style>
