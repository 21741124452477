import Vue from 'vue'
import {
	Button,
	Carousel,
	CarouselItem,
	Backtop,
	Loading,
	Image,
	Link,
	Upload,
	Input,
	Radio,
	DatePicker,
	Message,
	Dropdown,
	DropdownMenu,
	DropdownItem,
	MessageBox,
	Pagination,
	Drawer,
	InputNumber,
	Select,
	Option,
	Tabs,
	tabPane,
	Form,
	formItem,
	Popover,
	Cascader,
	Divider 
} from 'element-ui'

Vue.use(Button)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Backtop)
Vue.use(Image)
Vue.use(Link)
Vue.use(Upload)
Vue.use(Input)
Vue.use(Radio)
Vue.use(DatePicker)
Vue.use(Loading.directive)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Pagination)
Vue.use(Drawer)
Vue.use(InputNumber)
Vue.use(Select)
Vue.use(Option)
Vue.use(Tabs)
Vue.use(tabPane)
Vue.use(Form)
Vue.use(formItem)
Vue.use(Popover)
Vue.use(Cascader)
Vue.use(Divider)
 




Vue.prototype.$loading = Loading.service
Vue.prototype.$message = Message
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt