import Vue from 'vue'
import Axios from 'axios'
// import qs from 'qs'
import store from '../store/index'
import router from '../router/index'
import {
  Message
} from 'element-ui'


Vue.prototype.$axios = Axios;


//设置baseURL
// Axios.defaults.baseURL = '';

//请求头
Axios.defaults.headers['Content-Type'] = 'application/json';
Axios.defaults.headers['platform'] = 'pc';

Axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  // if (config.method === 'post') {
  //   //将请求参数进行转换，这里是全局配置post请求参数
  //   config.data = qs.stringify(config.data)
  // }
  const access_token = store.state.access_token;
  access_token && (config.headers['access-token'] = access_token);
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});


// 响应拦截器
Axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是200的情况    
  error => {
    if (error.response.status) {
      switch (error.response.status) {
        // 401: 未登录                
        // 未登录则跳转登录页面，并携带当前页面的路径                
        // 在登录成功后返回当前页面，这一步需要在登录页操作。  

        // case 401:
        //   router.replace({
        //     path: '/login',
        //     query: {
        //       redirectName: router.currentRoute.name
        //     }
        //   });
        //   break;

        // 403 token过期                
        // 登录过期对用户进行提示                
        // 清除本地token和清空vuex中token对象                
        // 跳转登录页面                
        case 401:
          Message.error({
            message: '登录过期，请重新登录',
          });
          // 清除token                    
          localStorage.removeItem('user_info');
          store.dispatch('loginLogout', null);
          // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
          setTimeout(() => {
            router.replace({
              path: '/login',
              query: {
                redirectName: router.currentRoute.name
              }
            });
          }, 1000);
          break;
          // 404请求不存在                
        case 404:
          Message.error({
            message: '网络请求不存在',
          });
          break;
          // 其他错误，直接抛出错误提示                
        default:
          Message.error({
            message: error.response.data.message,
          });
      }
      return Promise.reject(error.response);
    }
  }
);