import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element'
import './plugins/axios'
import VueCookies from 'vue-cookies'
import 'fullpage.js/vendors/scrolloverflow';
import VueFullPage from 'vue-fullpage.js';
Vue.use(VueCookies);
Vue.use(VueFullPage);

// 加入百度统计
router.beforeEach((to, from, next) => {
	if (to.path) {
		if (window._hmt) {
			window._hmt.push(['_trackPageview', '/#' + to.fullPath])
		}
	}
	next()
})

async function refresh() {
	if (localStorage.getItem('user_info')) {
		await store.dispatch('loginLogout', JSON.parse(localStorage.getItem('user_info')))
	}
	await store.dispatch('isLogin')
}
refresh()
Vue.config.productionTip = false
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')